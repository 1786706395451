<template>
  <div
    class="main baseinfo"
    :class="[edit ? 'edit' : '', status]"
    v-loading="loading"
  >
    <div class="tit">
      <span>信息认证</span>
      <i class="statusTxt statusTxt1">已认证</i>
      <i class="statusTxt statusTxt2">审核不通过</i>
      <i class="statusTxt statusTxt3">审核中</i>
    </div>
    <div class="errmsg">未通过原因：{{ refuse_reason }}</div>
    <div class="form"  v-if="!isIp">
      <div class="li">
        <div class="lab"><span class="need">*</span>公司名称</div>
        <div class="val">
          <input
            type="text"
            v-model="info.company"
            class="input1"
            :readonly="!edit"
            placeholder="请输入公司名称"
            maxlength="50"
          />
        </div>
      </div>
      <div class="li verifyCode">
        <div class="lab"><span class="need">*</span>联系人邮箱</div>
        <div class="val">
          <input
            type="text"
            v-model="info.email"
            class="input1"
            :readonly="!edit"
            placeholder="请输入企业邮箱"
          /><a class="btnSend" @click="getCode" ref="btnSend">获取验证码</a>
        </div>
      </div>
      <div class="li emailCodeBox">
        <div class="lab"><span class="need">*</span>验证码</div>
        <div class="val">
          <input
            type="text"
            v-model="info.code"
            class="input1"
            :readonly="!edit"
            placeholder="请输入验证码"
            maxlength="50"
          />
        </div>
      </div>
      <div class="li">
        <div class="lab"><span class="need">*</span>联系人姓名</div>
        <div class="val">
          <input
            type="text"
            v-model="info.name"
            class="input1"
            :readonly="!edit"
            placeholder="请输入联系人姓名"
            maxlength="50"
          />
        </div>
      </div>
      <div class="li">
        <div class="lab"><span class="need">*</span>联系人微信</div>
        <div class="val">
          <input
            type="text"
            v-model="info.wechat"
            class="input1"
            :readonly="!edit"
            placeholder="请输入联系人微信"
          />
        </div>
      </div>
      <div class="li">
        <div class="lab"><span class="need">*</span>联系人职位</div>
        <div class="val">
          <input
            type="text"
            v-model="info.position"
            class="input1"
            :readonly="!edit"
            placeholder="请输入联系人职位"
            maxlength="50"
          />
        </div>
      </div>
      <div class="li">
        <div class="lab"><span class="need">*</span>所在行业</div>
        <div class="val">
          <el-select
            v-model="info.industry"
            :disabled="!edit"
            placeholder="选择您的行业"
            class="select"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              :key="item.id"
              v-for="(item, index) in industryList"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="li">
        <div class="lab"><span class="need">*</span>联系人电话</div>
        <div class="val">
          <input
            type="text"
            v-model="info.phone"
            class="input1"
            :readonly="!edit"
            maxlength="11"
            placeholder="请输入联系人电话"
          />
        </div>
      </div>
      <div>
        <a class="btn1 btnSave" @click="saveInfo">提交</a>
        <a class="btn1 btnModfiy" @click="btnModfiy">修改</a>
      </div>
    </div>
		<ipAuth v-else :data="info" :onOk="successCallback" ></ipAuth>
  </div>
</template>
<script type="text/javascript">
import ipAuth from "./ipAuth.vue";
import icom from "@/common/com.js";
import utils from "@/common/utils.js";
import useStore from "@/store/index.js";
export default {
	components: {
    ipAuth,
  },
  data() {
    return {
      info: {
        company: "",
        email: "",
        name: "",
        wechat: "",
        position: "",
        industry: "",
        phone: "",
        code: "",
      },
      status: "", //认证状态 statusSuc statusIng statusFaild 默认空；
      edit: false,
      loading: true,
      refuse_reason: "",
      industryList: [],
      // emailStr: [
      //   "@126.com",
      //   "@163.com",
      //   "@sina.com",
      //   "@sina.cn",
      //   "@21cn.com",
      //   "@sohu.com",
      //   "@yahoo.com.cn",
      //   "@tom.com",
      //   "@qq.com",
      //   "@etang.com",
      //   "@hotmail.com",
      //   "@msn.com",
      //   "@yahoo.com",
      //   "@gmail.com",
      //   "@foxmail.com",
      //   "@139.com",
      //   "@vip.sina.com",
      // ],
      isIp: false,
    };
  },
  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    let { checkStatus,type } = this.logins;
    this.isIp = type === 2;
    console.log(checkStatus)
    if (!checkStatus) {
      this.edit = true;
    } else if (checkStatus == 1) {
      this.status = "statusIng";
    } else if (checkStatus == 2) {
      this.status = "statusSuc";
    } else if (checkStatus == 3) {
      this.status = "statusFaild";
      this.refuse_reason = this.logins.loginInfo.refuse_reason;
    }
    
    this.API.industry_dict({}, (res) => {
      // 获取行业列表
      this.industryList = res.data;
    });
    this.API.get_basic_info({}, (res) => {
      // 补充初始的公司介绍信息
      if(res.data.type === 1) {
        res.data.introduce_text = res.data.introduce || '';
        res.data.introduce = res.data.introduce || res.data.introduce_file_name || '';
      }
      // 获取基本信息
      this.info = res.data;
      this.loading = false;
    });
  },
  methods: {
    btnModfiy() {
      this.info.code = "";
      this.edit = true;
    },
    getCode() {
      let isEmail = this.checkEmail();
      if (!isEmail) return;
      // 发送邮件 接口 source 1：绑定邮箱，2：信息认证
      this.API.send_email_code({ email: this.info.email, source: 2,needCompany:1 }, (res) => {
        icom.countdown(this.$refs.btnSend, 60);
      });
    },
    checkEmail() {
      if (this.info.email == "") {
        this.store.showToast("请输入邮箱");
        return false;
      }
      if (!icom.checkStr(this.info.email, 2)) {
        this.store.showToast("请输入正确的邮箱");
        return false;
      }
      // var isEmailStr = this.emailStr.some((item) => {
      //   return this.info.email.endsWith(item);
      // });
      // if (isEmailStr) {
      //   this.store.showToast("请填写企业邮箱");
      //   return false;
      // }
      return true;
    },
    saveInfo() {
      if (this.info.company == "") {
        this.store.showToast("请输入公司名称");
        return;
      }
      if (this.info.email == "") {
        this.store.showToast("请输入邮箱");
        return false;
      }
      if (!icom.checkStr(this.info.email, 2)) {
        this.store.showToast("请输入正确的邮箱");
        return false;
      }
      // var isEmailStr = this.emailStr.some((item) => {
      //   return this.info.email.endsWith(item);
      // });
      // if (isEmailStr) {
      //   this.store.showToast("请填写企业邮箱");
      //   return false;
      // }

      if (this.info.code == "") {
        this.store.showToast("请输入验证码");
        return;
      }

      if (this.info.name == "") {
        this.store.showToast("请输入联系人姓名");
        return;
      }
      if (this.info.wechat == "") {
        this.store.showToast("请输入联系人微信");
        return;
      }
      if (this.info.position == "") {
        this.store.showToast("请输入联系人职位");
        return;
      }
      if (this.info.industry == "") {
        this.store.showToast("请选择所在行业");
        return;
      }
      if (this.info.phone == "") {
        this.store.showToast("请输入联系人电话");
        return false;
      }
      if (!icom.checkStr(this.info.phone)) {
        this.store.showToast("请输入正确的联系人电话");
        return false;
      }

      let { company, email, name, wechat, position, industry, phone, code } = {
        ...this.info,
      };
      this.API.save_basic_info(
        { company, email, name, wechat, position, industry, phone, code },
        (res) => {
          this.edit = false;
          this.successCallback()
        }
      );
    },
    successCallback(){
          console.log('successCallback')
          // 返回设置页面
          utils.get_info(this.API, this.store);
          this.$router.push("/person/baseinfo");
          this.store.showToast("保存成功", "success");
          this.status = "statusIng";
    }
  },
};
</script>
<style type="text/css">
.form input[readonly]:focus {
  border: 1px solid #fff !important;
}
</style>
<style type="text/css" scoped="">
.main .statusTxt {
  height: 24px;
  line-height: 24px;
  padding: 0 12px;
  border-radius: 3px;
  font-size: 12px;
  display: none;
  margin-left: 34px;
}
.statusSuc .statusTxt1 {
  background: -webkit-linear-gradient(left, #ffb253, #ff7f3e);
  color: #7b3912;
  display: inline-block;
}
.statusFaild .statusTxt2 {
  background: -webkit-linear-gradient(left, #e9e7e5, #c4bebb);
  color: #645e5b;
  display: inline-block;
}
.statusIng .statusTxt3 {
  background: -webkit-linear-gradient(left, #ffe7cb, #fbceb3);
  color: #8d5435;
  display: inline-block;
}
.errmsg {
  font-size: 14px;
  color: #ea2222;
  line-height: 20px;
  padding-left: 60px;
  display: none;
  margin-top: 24px;
  margin-bottom: -24px;
}
.statusFaild .errmsg {
  display: block;
}
.main {
  flex: 1;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
}
.form {
  padding: 20px 30px;
}
.form .li {
  flex: auto;
  width: 50%;
  display: block;
}
.verifyCode{
  position: relative;
}
.verifyCode .input1 {
}
.verifyCode .btnSend {
  position: absolute;
  right: 0;
  top: 0;
  width: 102px;
  height: 44px;
  background: #f5630b;
  border-radius: 0px 6px 6px 0px;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
}
.form .li {
  margin-top: 28px;
}
.form .input1 {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: 1px solid #fff;
  padding: 0 26px;
  font-size: 16px;
  color: #040404;
}

.form .select {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
}
.form .select :deep(.el-input) {
  height: 44px;
  line-height: 44px;
}
.form .select :deep(.el-input__inner) {
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  padding: 0 26px;
  color: #040404;
}

/* :deep(.el-input__inner):focus {
  border: 1px solid #f5630b !important;
} */
:deep(.el-input__inner)::-webkit-input-placeholder {
  color: #888;
}
:deep(.el-input__inner):-moz-placeholder {
  color: #888;
}
:deep(.el-input__inner)::-moz-placeholder {
  color: #888;
}
:deep(.el-input__inner):-ms-input-placeholder {
  color: #888;
}
.form .select :deep(.el-input.is-focus .el-input__wrapper  ) {
  box-shadow: 0 0 0 1px #f5630b inset !important;
}
.form .select :deep( .el-input__wrapper.is-focus  ) {
  box-shadow: 0 0 0 1px #f5630b inset !important;
}
.form .select :deep(.el-input.is-disabled .el-input__wrapper) {
  box-shadow: none;
  background: transparent;
}
.form .select :deep(.el-input.is-disabled .el-select__caret) {
  display: none;
}
.form .select :deep(.el-input.is-disabled .el-input__inner) {
  cursor: text;
}
.el-select-dropdown__item.selected {
  color: #f5630b;
}
.form .li .lab {
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 12px;
  font-size: 14px;
  color: #606060;
  line-height: 44px;
  vertical-align: top;
}
.form .li .lab .need {
  color: #ff9c00;
  display: none;
}
.form .li .val {
  display: inline-block;
  width: 360px;
}
.baseinfo .btn1 {
  width: 360px;
  height: 44px;
  background: #f5630b;
  border-radius: 6px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 37px auto 0;
}
.btnSave {
  display: none;
}
.baseinfo .btnModfiy {
  display: block;
  width: 118px;
  float: right;
  margin-right: 60px;
}
.form .li.emailCodeBox {
  display: none;
}
.form .li .btnSend {
  display: none;
}

.edit .li .btnSend {
  display: block;
}
.edit .li.emailCodeBox {
  display: block;
}
.edit .btnSave {
  display: block;
}
.edit .btnModfiy {
  display: none;
}
.edit .li .lab .need {
  display: inline-block;
}
.edit .emailCodeBox {
  display: block;
}
.edit .input1 {
  border: 1px solid #e3e6ec;
}
</style>
