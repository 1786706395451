<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="130px"
      :class="{ ip_ruleForm: true, ip_ruleForm_readyOnly: isReadyOnly }"
      size="large"
      :disabled="isReadyOnly"
      :hide-required-asterisk="isReadyOnly"
      :validate-on-rule-change="false"
    >
      <el-form-item label="认证性质" prop="type">
        <span class="type_text" v-if="isReadyOnly">企业</span>
        <el-radio-group v-else class="ip_form_radio" v-model="ruleForm.type">
          <el-radio
            v-for="item in TYPE_OPTIONS"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <div v-if="isPersonalEdit" class="form_tip">
        温馨提示：您填写的姓名以及身份证号只是用户平台审核信息，不会用在其他地方，请放心填写
      </div>
      <template v-if="ruleForm.type === 1">
        <el-form-item label="公司名称" prop="company_name">
          <el-input
            class="form_input"
            placeholder="请输入公司名称"
            v-model="ruleForm.company_name"
          />
        </el-form-item>
        <el-form-item label="营业执照" prop="business_license" class="wrapper-box">
          <div class="ip_form_upload_img">
            <img v-if="ruleForm?.business_license" :src="ruleForm?.business_license" />
          </div>
          <el-upload
            action="/api/user/upload-image2048"
            :headers="postHeader"
            v-model="fileList"
            :show-file-list="false"
            :on-success="onFileLoadSuccess" 
            accept="image/*"
            v-if="!isReadyOnly"
          >
            <div class="ip_form_upload_btn">上传</div>
            <template #tip>
              <div class="ip_form_upload_tip">
                请上传营业执照电子版原件或扫描件，支持.jpg/.png格式，大小不超过2MB
              </div>
            </template>
            <template #file> </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="公司介绍" prop="introduce" class="wrapper-box">
          <div class="company-profile-prefix">输入公司介绍或上传文件，二选一即可</div>
          <el-input v-show="false" v-model="ruleForm.introduce"></el-input>
          <el-input
            v-model="ruleForm.introduce_text"
            @change="handleIntroduceChange"
            maxlength="500"
            rows="5"
            placeholder="请输入公司介绍"
            show-word-limit
            type="textarea"
            class="company-profile"
            resize="none"
          />
          <div class="company-profile-file">
            <el-upload
              action="/api/user/upload-file"
              :headers="postHeader"
              v-model="companyIntroduce"
              :show-file-list="false"
              :on-success="onIntroduceLoadSuccess"
              accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/msword"
              v-if="!isReadyOnly"
            >
              <div class="ip_form_upload_btn">上传</div>
            </el-upload>
            <div class="company-profile-file-item">
              <span v-if="ruleForm?.introduce_file_name" v-on:click="() => handleDownload(ruleForm?.introduce_file_url)">{{ruleForm.introduce_file_name}}</span>
              <el-icon v-if="!isReadyOnly && ruleForm?.introduce_file_url" v-on:click="handleClearFile" color="#9EA4BA" class="icon-close"><Close /></el-icon>
            </div>
          </div>
          <div class="ip_form_upload_tip" v-if="!isReadyOnly">
            请上传公司介绍，支持.pdf/.word/.ppt/.jpg/.png格式，大小不超过100MB
          </div>
        </el-form-item>
        <el-form-item label="联系人邮箱" prop="email">
          <el-input
            class="form_input email_form_input"
            placeholder="请输入联系人邮箱"
            v-model="ruleForm.email"
          />
          <el-button
            v-if="!isReadyOnly"
            :color="codeTime === 0 ? '#F5630B' : '#F4F4F4'"
            :class="{ code_btn: true, code_btn_disable: codeTime !== 0 }"
            type="primary"
            @click="getCode"
            >{{
              codeTime === 0 ? "获取验证码" : `${codeTime}秒后再次发送`
            }}</el-button
          >
        </el-form-item>
        <el-form-item v-show="!isReadyOnly" label="验证码" prop="code">
          <el-input
            class="form_input"
            placeholder="请输入验证码"
            v-model="ruleForm.code"
          />
        </el-form-item>
        <el-form-item label="联系人姓名" prop="name">
          <el-input
            class="form_input"
            placeholder="请输入联系人姓名"
            v-model="ruleForm.name"
          />
        </el-form-item>
        <el-form-item label="联系人职位" prop="position">
          <el-input
            class="form_input"
            placeholder="请输入联系人职位"
            v-model="ruleForm.position"
          />
        </el-form-item>
        <el-form-item label="联系人电话" prop="phone">
          <el-input
            class="form_input"
            placeholder="请输入联系人电话"
            v-model="ruleForm.phone"
          />
        </el-form-item>
        <el-form-item label="联系人微信" prop="wechat">
          <el-input
            class="form_input"
            placeholder="请输入联系人微信"
            v-model="ruleForm.wechat"
          />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="真实姓名" prop="name">
          <el-input
            class="form_input"
            placeholder="请输入真实姓名"
            v-model="ruleForm.name"
          />
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card_number">
          <el-input
            class="form_input"
            placeholder="请输入身份证号"
            v-model="ruleForm.id_card_number"
          />
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            class="form_input"
            placeholder="请输入联系电话"
            v-model="ruleForm.phone"
          />
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input
            class="form_input email_form_input"
            placeholder="请输入联系邮箱"
            v-model="ruleForm.email"
          />
          <el-button
            v-if="!isReadyOnly"
            :color="codeTime === 0 ? '#F5630B' : '#F4F4F4'"
            :class="{ code_btn: true, code_btn_disable: codeTime !== 0 }"
            type="primary"
            @click="getCode"
            >{{
              codeTime === 0 ? "获取验证码" : `${codeTime}秒后再次发送`
            }}</el-button
          >
        </el-form-item>
        <el-form-item v-if="!isReadyOnly" label="验证码" prop="code">
          <el-input
            class="form_input"
            placeholder="请输入验证码"
            v-model="ruleForm.code"
          />
        </el-form-item>
        <div v-if="isPersonalEdit" class="form_tip2">
          如果你有IP的社交平台帐号也可填写辅助我们审核，只填写一个平台的即可
        </div>
        <el-form-item label="IP名称" prop="ip_name">
          <el-input
            class="form_input"
            placeholder="请输入IP名称"
            v-model="ruleForm.ip_name"
          />
        </el-form-item>
        <el-form-item label="社交平台" prop="platform">
          <span class="type_text" v-if="isReadyOnly">{{
            platform_config[ruleForm.platform]?.label
          }}</span>
          <el-radio-group
            v-else
            class="ip_form_radio"
            v-model="ruleForm.platform"
          >
            <el-radio
              v-for="item in PLATFORM_OPTIONS"
              :key="item.value"
              :label="item.value"
              ><img :src="item.icon" />{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="社交帐号" prop="account">
          <el-input
            class="form_input"
            placeholder="请输入社交帐号昵称"
            v-model="ruleForm.account"
          />
        </el-form-item>
        <el-form-item label="社交帐号后台截图" prop="image">
          <div class="ip_form_upload_img">
            <img v-if="ruleForm?.image" :src="ruleForm?.image" />
          </div>
          <el-upload
            action="/api/user/upload-image300"
            :headers="postHeader"
            v-model="fileList"
            :show-file-list="false"
            :on-success="onFileLoadSuccess"
            :before-upload="beforeUpload"
            accept="image/*"
            v-if="!isReadyOnly"
          >
            <div class="ip_form_upload_btn">上传</div>
            <template #tip>
              <div class="ip_form_upload_tip">
                请上传.png或.jpg格式图片，图片大小不超过300KB
              </div>
            </template>
            <template #file> </template>
          </el-upload>
        </el-form-item>
      </template>
    </el-form>
    <div :class="{ ip_form_submit: true, ip_form_submit_edit: isReadyOnly }">
      <el-button
        class="ip_auth_edit"
        color="#F5630B"
        type="primary"
        v-if="isReadyOnly"
        @click="isReadyOnly = !isReadyOnly"
      >
        修改
      </el-button>
      <el-button
        v-else
        color="#F5630B"
        class="ip_auth_submit"
        type="primary"
        @click="onSubmit()"
        >提交</el-button
      >
    </div>
  </div>
</template>
<script type="text/javascript">
import { Close } from "@element-plus/icons-vue";
import {
  TYPE_OPTIONS,
  enterprise_rules,
  enterprise_init,
  personal_init,
  personal_rules,
} from "./enum";
import icom from "@/common/com.js";
import { PLATFORM_OPTIONS, platform_config } from "@/common/enum";
import useStore from "@/store/index.js";
import { ElMessage } from "element-plus";
const TIME_COUNT = 60;
const DELAY = 1000;
export default {
  props: ["data", "onOk"],
  components:{
    Close,
  },
  data() {
    return {
      isReadyOnly: false,
      codeTime: 0,
      ruleForm: {},
      propsData: {},
      propsOnOk: null,
      TYPE_OPTIONS,
      PLATFORM_OPTIONS,
      platform_config,
      fileList: [],
      companyIntroduce: [],
      postHeader: {
        myToken: icom.getCookie("myToken"),
      },
    };
  },
  watch: {
    data(newValue) {
      this.ruleForm = { ...newValue };
      this.propsData = newValue;
    },
  },
  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    let { checkStatus } = this.logins;
    this.isReadyOnly = !!checkStatus;
  },
  computed: {
    rules() {
      this.codeTime = 0;
      this.ruleForm =
        this.ruleForm.type === this.propsData.type
          ? { ...this.propsData }
          : { type: this.ruleForm.type };
      return this.ruleForm.type === 1 ? enterprise_rules : personal_rules;
    },
    isPersonalEdit() {
      return this.ruleForm.type === 2 && !this.isReadyOnly;
    },
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.ruleFormRef) return;
      await this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          let data = {};
          if (this.ruleForm.type === 1) {
            const {
              type,
              company_name,
              email,
              name,
              position,
              phone,
              wechat,
              code,
              business_license,
              introduce_text,
              introduce_file_url,
              introduce_file_name,
            } = this.ruleForm;
            data = {
              type,
              company_name,
              email,
              name,
              position,
              phone,
              wechat,
              code,
              business_license,
              introduce: introduce_text || '',
              introduce_file_name,
              introduce_file_url,
            };
          } else if (this.ruleForm.type === 2) {
            const {
              type,
              name,
              id_card_number,
              phone,
              email,
              ip_name,
              platform,
              account,
              image,
              code,
            } = this.ruleForm;
            data = {
              type,
              name,
              id_card_number,
              phone,
              email,
              ip_name,
              platform,
              account,
              image,
              code,
            };
          }
          this.API.save_basic_info(data, (res) => {
            if (res.code !== 0) {
              return;
            }
            // 返回设置页面
            this.onOk?.();
            this.isReadyOnly = true;
            this.propsData = { ...this.ruleForm, code: "" };
          });
        }
      });
    },
    getCode() {
      const email = this.ruleForm.email;
      if (email == "") {
        this.store.showToast("请输入邮箱");
        return false;
      }
      if (!icom.checkStr(email, 2)) {
        this.store.showToast("请输入正确的邮箱");
        return false;
      }
      this.API.send_email_code({ email: email, source: 2 }, (res) => {
        if (res.code === 0) {
          this.getCodeTime();
        }
      });
    },
    getCodeTime() {
      this.codeTime = TIME_COUNT;
      let timer = null;
      timer = setInterval(() => {
        if (this.codeTime > 0 && this.codeTime <= TIME_COUNT) {
          this.codeTime--;
        } else {
          this.codeTime = 0;
          timer = null;
        }
      }, DELAY);
    },
    onFileLoadSuccess(res) {
      const { code, data, msg } = res;
      if (code === 0) {
        // 如果是企业认证，保存企业营业执照图片
        if(this.ruleForm?.type === 1) {
          this.ruleForm.business_license = data.file;
          return;
        }
        this.ruleForm.image = data.file;
        this.fileList = [];
      } else {
        ElMessage({
          message: msg,
          type: "error",
        });
      }
    },
    // 上传公司介绍文件
    onIntroduceLoadSuccess(res) {
      const { code, data, msg } = res;
      if (code === 0) {
        this.ruleForm.introduce_file_name = data.name;
        this.ruleForm.introduce_file_url = data.file;
        this.companyIntroduce = [];
        this.ruleForm.introduce = this.ruleForm?.introduce || data.name;
      } else {
        ElMessage({
          message: msg,
          type: "error",
        });
      }
    },
    beforeUpload(file) {
      const size = 300 * 1024;
      if (file.size > size) {
        ElMessage({
          message: `图片大小不能超过300KB`,
          type: "warning",
        });
        return false;
      }
    },
    // 删除已经上传的公司介绍文件
    handleClearFile(e) {
      if(this.ruleForm?.introduce && this.ruleForm.introduce !== this.ruleForm?.introduce_text) {
        this.ruleForm.introduce = '';
      }
      this.ruleForm.introduce_file_url = "";
      this.ruleForm.introduce_file_name = "";
      e.stopPropagation();
    },
    // 打开文件-跳转新页面
    handleDownload(url){
      window.open(url);
    },
    // 输入公司介绍
    handleIntroduceChange(value) {
      this.ruleForm.introduce_text = value;
      this.ruleForm.introduce = value || this.ruleForm?.introduce_file_name || '';
    }
  },
};
</script>

<style lang="less">
.ip_ruleForm {
  margin-top: 33px;
  .el-form-item {
    .el-form-item__label {
      color: #606060;
    }
  }
  .form_tip {
    color: #888888;
    margin: -22px 0 16px 120px;
  }
  .form_tip2 {
    font-size: 14px;
    color: #0b0b0b;
    margin: 10px 0 24px 20px;
  }
  .ip_form_upload_img {
    width: 192px;
    height: 144px;
    border-radius: 6px;
    border: 1px solid #e3e6ec;
    margin-right: 16px;
    overflow: hidden;
    > img {
      height: 100%;
      object-fit: cover;
    }
  }
  .ip_form_upload_btn {
    width: 112px;
    height: 40px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e3e6ec;
    cursor: pointer;
    color: #585d70;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 12px;
  }
  .ip_form_upload_tip {
    color: #888888;
  }
}
.form_input {
  width: 360px;
  // .el-input.is-focus {
  //   .el-input__wrapper {
  //     box-shadow: 0 0 0 1px #f5630b inset !important;
  //   }
  // }
  // .el-input__wrapper.is-focus {
  //   box-shadow: 0 0 0 1px #f5630b inset !important;
  // }
  .el-input__inner {
    color: #0b0b0b;
    font-size: 14px;
  }
}
.form_input.el-input.is-disabled {
  .el-input__wrapper {
    box-shadow: none;
    background: transparent;
  }
}
.email_form_input {
  width: 260px;
  .el-input__wrapper {
    border-radius: 4px 0px 0px 4px;
  }
}
.code_btn {
  border-radius: 0px 4px 4px 0px;
  > span {
    color: #ffffff;
  }
}
.code_btn_disable {
  pointer-events: none;
  color: #2e3036;
  border: 1px solid #e3e6ec;
  border-left: none;
  > span {
    color: #2e3036;
  }
  &:active {
    > span {
      color: #2e3036;
    }
  }
  &:focus {
    > span {
      color: #2e3036;
    }
  }
}
.ip_form_submit {
  .ip_auth_edit {
    height: 44px;
    width: 118px;
    > span {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .ip_auth_submit {
    width: 360px;
    height: 44px;
    border-radius: 6px;
    margin-left: 120px;
    > span {
      font-size: 14px;
      color: #ffffff;
    }
  }
}
.ip_form_submit_edit {
  display: flex;
  justify-content: flex-end;
  padding-right: 60px;
}
.ip_form_radio {
  width: 380px;
  .el-radio {
    width: 63px;
    // .is-checked {
    //   .el-radio__inner {
    //     border-color: #f5630b;
    //     background: #f5630b;
    //   }
    // }
    .el-radio__label {
      color: #0b0b0b;
      > img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}
.ip_ruleForm_readyOnly {
  .type_text {
    font-size: 14px;
    color: #0b0b0b;
    margin-left: 15px;
  }
  .el-form-item {
    .el-form-item__content {
      .is-disabled {
        .el-input__inner {
          background-color: transparent;
          border: none;
          color: #0b0b0b;
          cursor: auto;
        }
      }

    }
  }
  .wrapper-box {
    .el-form-item__content{
      padding: 1px 15px;
    }
  }
}
.company-profile-prefix {
  line-height: 40px;
  width: 100%;
}
.company-profile {
  width: 648px;
}
.company-profile-file {
  margin-top: 16px;
  width: 100%;
  display: flex;
  span {
    line-height: 40px;
    text-decoration: underline;
    color: #F5630B;
    margin-bottom: 12px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 500px;
  }
  div[modelvalue] + .company-profile-file-item {
    margin-left: 16px;
  }
  .icon-close {
    line-height: 40px;
    margin-left: 8px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .company-profile-file-item {
    display: flex;
    align-items: center;
  }
}
</style>
