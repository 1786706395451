export const TYPE_OPTIONS = [
  {
    label:'企业',
    value:1
  },
  {
    label:'个人',
    value:2
  },
]
export const enterprise_rules = {
  type:[
    {
      required: true,
      message: "请选择认证性质",
      trigger: "blur",
    },
  ],
  company_name: [
    {
      required: true,
      message: "请输入公司名称",
      trigger: "blur",
    },
    {
      max: 50,
      message: "公司名称超长",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: "请输入联系人邮箱",
      trigger: "blur",
    },
    {
      type: "email",
      message: "请输入正确的邮箱",
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: "请输入验证码",
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入联系人姓名",
      trigger: "blur",
    },
    {
      max: 50,
      message: "联系人姓名超长",
      trigger: "blur",
    },
  ],
  position: [
    {
      required: true,
      message: "请输入联系人职位",
      trigger: "blur",
    },
    {
      max: 50,
      message: "联系人职位超长",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入联系人电话",
      trigger: "blur",
    },
    {
      max: 50,
      message: "联系人电话超长",
      trigger: "blur",
    },
  ],
  wechat: [
    {
      required: true,
      message: "请输入联系人微信",
      trigger: "blur",
    },
    {
      max: 50,
      message: "联系人微信超长",
      trigger: "blur",
    },
  ],
  business_license: [
    {
      required: true,
      message: "请上传营业执照",
      trigger: "blur",
    },
  ],
  introduce: [
    {
      required: true,
      message: "请填写公司简介或者上传公司简介文件",
      trigger: "change",
    },
  ],
};
export const enterprise_init = {
  type: 1,
  company: "123",
  email: "123",
  code: "123123",
  name: "123",
  job: "123",
  phone: "123",
}
export const personal_rules = {
  type:[
    {
      required: true,
      message: "请选择认证性质",
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入真实姓名",
      trigger: "blur",
    },
    {
      max: 20,
      message: "真实姓名不能超出20个字符",
      trigger: "blur",
    },
  ],
  id_card_number: [
    {
      required: true,
      message: "请输入身份证号",
      trigger: "blur",
    },
  ],
  phone:[
    {
      required: true,
      message: "请输入联系人电话",
      trigger: "blur",
    },
    {
      max: 50,
      message: "联系人电话超长",
      trigger: "blur",
    },
  ],
  email:[
    {
      required: true,
      message: "请输入联系人邮箱",
      trigger: "blur",
    },
    {
      type: "email",
      message: "请输入正确的邮箱",
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: "请输入验证码",
      trigger: "blur",
    },
  ],
  ip_name:[
    {
      max: 50,
      message: "IP名称不能超出50个字符",
      trigger: "blur",
    },
  ],
  account:[
    {
      max: 50,
      message: "社交帐号不得超出50个字符",
      trigger: "blur",
    },
  ]
}
export const personal_init = {
  type:2,
  realName: 'realName',
  idNumber: "idNumber",
  phone: "phone",
  email: "email",
  code: "code",
  ipName: "ipName",
  socialAccount: "socialAccount",
}